<template>
  <div>
    <div class="select-box">
      <!-- 1.筛选条件 -->
      <div>
        <span>产品名称:</span>
        <el-input
          v-model="option.productName"
          clearable
          placeholder="请输入产品名称"
        ></el-input>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary"
          >查 询</el-button
        >
      </div>
    </div>
    <!--2. table表格的内容-->
    <el-table id="table" border stripe :data="data.table" style="width: 100%">
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column>
      <!--隐藏列-->
      <el-table-column
        type="index"
        :index="indexMethod"
        width="50"
        style="text-align: center"
      >
      </el-table-column>
      <el-table-column prop="code" label="编码"> </el-table-column>
      <el-table-column prop="productName" label="产品名称"> </el-table-column>
      <el-table-column prop="standardCost" label="标准价(元)">
      </el-table-column>
      <el-table-column prop="lowestPrice" label="最低价(元)"> </el-table-column>
      <el-table-column prop="capacityNum" label="规格"> </el-table-column>
      <el-table-column prop="capacityType" label="单位"> </el-table-column>
      <el-table-column prop="productID" label="项目编号"> </el-table-column>
      <el-table-column prop="remarks" label="备注"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template #default="scope">
          <el-button
            @click="showForm(scope.row)"
            size="small"
            icon="el-icon-edit"
            type="primary"
            >发布</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="10"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>

    <!-- 弹窗 -->
    <el-dialog
      width="52%"
      title="产品信息"
      v-model="ruleForm.dialogFormVisible"
      :show-close="true"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="*商品分类">
          <el-col :span="11">
            <el-select
              v-model="ruleForm.categoryID"
              placeholder="请选择商品分类"
            >
              <el-option
                v-for="item in data.categoryListData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col class="line" :span="4">
            &nbsp;&nbsp;&nbsp;&nbsp;虚拟数量&nbsp;&nbsp;&nbsp;</el-col
          >
          <el-col :span="11">
            <el-form-item prop="bannerSort">
              <el-input
                v-model="ruleForm.virtualAmount"
                type="number"
                placeholder="数字"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item label="备注">
          <el-col :span="11">
            <el-form-item prop="bannerMemo">
              <el-input
                v-model="ruleForm.remark"
                clearable
                placeholder="备注信息"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <div v-if="ruleForm.imageDiv">
          <el-image :src="ruleForm.imageUrl" :fit="fit"></el-image>
          <el-button
            @click="imageClose"
            style="position: absolute; margin-left: -40px"
            type="danger"
            icon="el-icon-close"
            circle
          ></el-button>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="ToGoods('ruleForm')"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
import { reactive } from "vue";
import { getProductList } from "@/http/api";
import { getCategoryList } from "@/http/goods";
import { createGoods } from "@/http/goods";

import { ElMessage } from "element-plus";
export default {
  name: "Product",
  setup() {
    //声明数据
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      categoryListData: [],
    });

    //声明查询条件
    let option = reactive({
      page: 1,
      pageSize: 10,
      productName: "",
    });

    let indexMethod = (index) => {
      return index + (option.page - 1) * option.pageSize + 1;
    };

    //修改的表单参数//////////////////////////////////////////////////
    let ruleForm = reactive({
      dialogFormVisible: false,
      categoryID: "",
      virtualAmount: 0,
      remark: "",
    });

    let getCategoryListDataFun = async (obj) => {
      let categoryList = await getCategoryList(obj);
      data.categoryListData = categoryList;
    };

    //获取产品列表方法
    let getProductListFun = async (obj) => {
      let arr = await getProductList(obj);
      data.total = arr.totalCount;
      data.table = arr.items;
    };

    // console.log('getProductListFun');
    // console.log(getProductListFun);

    //初始化时，获取初始化数据
    getProductListFun({ Page: 1, PageSize: 10, productName: "" });
    getCategoryListDataFun({});

    //表单重置
    let resetForm = (formName) => {
      ruleForm.dialogFormVisible = false;
      ruleForm.virtualAmount = "";
      ruleForm.remark = "";
      ruleForm.categoryID = "";
    };

    let showForm = (obj) => {
      ruleForm.dialogFormVisible = true;
      ruleForm.productID = obj.productID;
      ruleForm.productName = obj.productName;
      ruleForm.standardCost = obj.standardCost;
      ruleForm.lowestPrice = obj.lowestPrice;
    };

    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getProductListFun(option);
    };
    /** 图片查看 */
    let getImgList = (workPhoto, index) => {
      let arr = [];
      if (workPhoto.length == 1) {
        arr.push(workPhoto[0]);
      } else if (workPhoto.length == 0) {
        return arr;
      } else {
        for (let i = 0; i < workPhoto.length; i++) {
          arr.push(workPhoto[i + index]);
          if (i + index >= workPhoto.length - 1) {
            index = 0 - (i + 1);
          }
        }
      }
      return arr;
    };

    let ToGoods = async (formName) => {
      let params = {
        shopID: "0",
        categoryID: ruleForm.categoryID,
        itemID: ruleForm.productID, //
        goodsType: 1,
        goodsName: ruleForm.productName, //
        summary: "",
        description: "",
        detailTopPic: "",
        listPic: "",
        price: ruleForm.standardCost, //
        virtualAmount: ruleForm.virtualAmount,
        remark: ruleForm.remark,
        lowestPrice: ruleForm.lowestPrice,
      };
      //console.log(params);

      let result = await createGoods(params);
      ElMessage.error({
        message: result.message,
        duration: 2000,
      });
      resetForm();
      //console.log(result);
    };

    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      option.page = 1;
      getProductListFun(option);
    };

    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      getImgList,
      ToGoods,
      ruleForm,
      resetForm,
      showForm,
      indexMethod,
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 14%;
    display: flex;
    align-items: center;
    span {
      width: 120px;
    }
    margin-right: 20px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
